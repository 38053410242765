import useData from '@/hooks/useData';

const useSession = () => {
    const {currentUser, team} = useData(['currentUser', 'team']);

    const authorisedTo = (ability: string, model: any) => {
        if (typeof model === 'string') {
            return currentUser.permissions?.[model]?.[ability] || false;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return model.permissions?.[ability] || false;
    };

    const hasAnyAbility = (model: any, abilities: string[] = []) => {
        let result = false;

        abilities.forEach(ability => {
            if (authorisedTo(ability, model) === true) {
                result = true;
            }
        });

        return result;
    };

    const hasCustomPermission = (customPermission: string) => {
        return currentUser?.custom_permissions?.includes(customPermission);
    };

    const isLoggedIn = () => !!currentUser;

    return {
        authorisedTo,
        currentTeam: team,
        currentUser,
        hasAnyAbility,
        hasCustomPermission,
        isLoggedIn
    };
};

export default useSession;
