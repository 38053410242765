import {useMemo} from 'react';
import {isAfter} from 'date-fns';
import useData from './useData';
import useI18n from './useI18n';

const useSubscription = () => {
    const {t} = useI18n();
    const {billable, currentUser, monthlyPlans, plan, state, teams, usage, yearlyPlans} = useData([
        'billable',
        'currentUser',
        'monthlyPlans',
        'plan',
        'state',
        'teams',
        'usage',
        'yearlyPlans'
    ]);

    const hasTrialPeriodEnded = () => {
        // trial end date may be null if a user has cancelled an active subscription.
        // In this case the trial will definitely be over.
        if (!billable.trial_ends_at) return true;
        // Otherwise check if we are within the trial period.
        return isAfter(new Date(billable.trial_ends_at), new Date());
    };

    // Trial options are not sent with the page data. These will be hardcoded until we have a way
    // to set them in the backend.
    const trialOptions: SubscriptionPlan['options'] = {
        teams: 1,
        admins: 1,
        team_admins: 1,
        researchers: 5,
        interviewers: 10,
        interviews_in_person: 25,
        interview_minutes: 120,
        interview_minutes_price_id: 0,
        transcription_minutes: 120,
        transcription_minutes_price_id: 0
    };
    const isTrial = state === 'none';
    // If there is no state for the subscription it is considered in trial. Even when expired,
    // a trial is still considered 'active'. That's because there is no other fallback state.
    const hasActiveTrial = state === 'none';
    const hasExpiredTrial = useMemo(() => hasActiveTrial && !hasTrialPeriodEnded(), []);
    // Certain plans (e.g. Shaper) are managed off-platform and do not need subscription
    // management tools on the frontend.
    const isManagedPlan = plan?.features?.managed ?? false;
    // Fatima Light is a special plan. All other plans are considered 'pro'
    const hasLightSubscription = plan && plan?.name === 'Fatima Light';
    const isProSubscriber = !hasLightSubscription;

    // Plans are stored by their interval in Stripe. Each plan has the same feature counts regardless
    // of interval so we can take the details for each from the first plan in the array.
    const planSummaries = useMemo(() => {
        const starterMonthlyPlan = monthlyPlans?.find(p => p.name === 'Starter');
        const starterYearlyPlan = yearlyPlans?.find(p => p.name === 'Starter');
        const supportedMonthlyPlan = monthlyPlans?.find(p => p.name === 'Supported');
        const supportedYearlyPlan = yearlyPlans?.find(p => p.name === 'Supported');

        if (
            !starterMonthlyPlan ||
            !starterYearlyPlan ||
            !supportedMonthlyPlan ||
            !supportedYearlyPlan
        ) {
            return null;
        }

        const summaries: SubscriptionPlanSummary[] = [
            {
                name: t('subscription.plans.starter'),
                key: 'Starter',
                description: t('subscription.plans.starterDescription'),
                cost: {
                    monthly: starterMonthlyPlan.price,
                    yearly: starterYearlyPlan.price
                },
                features: [
                    {enabled: true, label: t('subscription.plans.starterOrgAdmins')},
                    {enabled: true, label: t('subscription.plans.starterTeams')},
                    {enabled: true, label: t('subscription.plans.starterTeamAdmins')},
                    {enabled: true, label: t('subscription.plans.starterResearchers')},
                    {enabled: true, label: t('subscription.plans.starterInterviewers')},
                    {enabled: false, label: t('subscription.plans.starterEnumeratorAccess')}
                ],
                languages: [true, true, true],
                surveyTypes: [
                    true,
                    true,
                    true,
                    t('subscription.plans.starterInPersonInterviews'),
                    t('subscription.plans.starterRemoteInterviewMinutes')
                ],
                support: [true, false, t('subscription.plans.starterSupport')]
            },
            {
                name: t('subscription.plans.supported'),
                key: 'Supported',
                description: t('subscription.plans.supportedDescription'),
                cost: {
                    monthly: supportedMonthlyPlan.price,
                    yearly: supportedYearlyPlan.price
                },
                features: [
                    {enabled: true, label: t('subscription.plans.supportedOrgAdmins')},
                    {enabled: true, label: t('subscription.plans.supportedTeams')},
                    {enabled: true, label: t('subscription.plans.supportedTeamAdmins')},
                    {enabled: true, label: t('subscription.plans.supportedResearchers')},
                    {enabled: true, label: t('subscription.plans.supportedInterviewers')},
                    {enabled: false, label: t('subscription.plans.supportedEnumeratorAccess')}
                ],
                languages: [true, true, true],
                surveyTypes: [
                    true,
                    true,
                    true,
                    t('subscription.plans.supportedInPersonInterviews'),
                    t('subscription.plans.supportedRemoteInterviewMinutes')
                ],
                support: [
                    true,
                    t('subscription.plans.supportedOnboarding'),
                    t('subscription.plans.supportedSupport')
                ]
            },
            {
                name: t('subscription.plans.tailored'),
                key: 'Tailored',
                description: t('subscription.plans.tailoredDescription'),
                cost: null,
                features: [
                    {enabled: true, label: t('subscription.plans.tailoredOrgAdmins')},
                    {enabled: true, label: t('subscription.plans.tailoredTeams')},
                    {enabled: true, label: t('subscription.plans.tailoredTeamAdmins')},
                    {enabled: true, label: t('subscription.plans.tailoredResearchers')},
                    {enabled: true, label: t('subscription.plans.tailoredInterviewers')},
                    {enabled: true, label: t('subscription.plans.tailoredEnumeratorAccess')}
                ],
                languages: [true, true, true],
                surveyTypes: [
                    true,
                    true,
                    true,
                    t('subscription.plans.tailoredInPersonInterviews'),
                    t('subscription.plans.tailoredRemoteInterviewMinutes')
                ],
                support: [
                    true,
                    t('subscription.plans.tailoredOnboarding'),
                    t('subscription.plans.tailoredSupport')
                ]
            }
        ];

        return summaries;
    }, [monthlyPlans, yearlyPlans]);

    const getUsageTheme = (data: SubscriptionPlanUsage) => {
        if (data.hasExceeded) {
            return 'destructive';
        } else if (data.isNearlyExceeded) {
            return 'warning';
        } else {
            return 'default';
        }
    };

    const getUsage = () => {
        if (!plan) return;

        // These are the main keys that each plan is measured by. The keys are the same for each plan
        // but the values are different. Some plans have a hard limit, others allow unlimited usage.
        const groupedMetricKeys = {
            teams: ['admins', 'team_admins', 'teams', 'researchers'],
            interviews: ['interviewers', 'interviews_in_person']
        };

        // Fatima Light subscribers do not have access to remove interviews, so we don't need to
        // show the interview minutes metric.
        if (isProSubscriber) {
            groupedMetricKeys.interviews.push('interview_minutes');
        }

        const planUsage = Object.entries(groupedMetricKeys).map(([group, keys]) => {
            const stats = keys.reduce((acc, key) => {
                const metricMax = plan.options[key] || 'unlimited';
                const isUnlimited = metricMax === 'unlimited';
                const count = usage[`${key}_count`];
                const hasExceeded = isUnlimited ? false : count > metricMax;
                const isNearlyExceeded = isUnlimited ? false : count >= metricMax * 0.8;
                const progress = isUnlimited ? 100 : Math.floor((count / metricMax) * 100);
                const data = {
                    count,
                    hasExceeded,
                    isNearlyExceeded,
                    isUnlimited,
                    max: metricMax,
                    metric: key,
                    progress: progress > 100 ? 100 : progress,
                    theme: '',
                    unit: null
                };

                return [...acc, {...data, theme: getUsageTheme(data)}];
            }, [] as SubscriptionPlanUsage[]);

            return {group, usage: stats};
        });

        return planUsage;
    };

    const hasActiveSubscription = () => {
        return state === 'active' || state === 'past_due' || state === 'onGracePeriod';
    };

    const hasExceededUsage = (metric: string): boolean => {
        const metricMax = isTrial ? trialOptions[metric] : plan?.options[metric] || 'unlimited';
        const isUnlimited = metricMax === 'unlimited';
        const count = usage[`${metric}_count`];
        const hasExceeded = isUnlimited ? false : count >= metricMax;

        return hasExceeded;
    };

    // When a user has just signed up we need to present onboarding information to them.
    const isNewSubscription = () => {
        return !teams?.data?.length && currentUser.home_url.includes('/teams');
    };

    return {
        currentPlan: plan,
        getUsage,
        hasActiveSubscription,
        hasActiveTrial,
        hasExpiredTrial,
        hasExceededUsage,
        isProSubscriber,
        isNewSubscription,
        isTrial,
        isManagedPlan,
        monthlyPlans,
        plan,
        planSummaries,
        yearlyPlans
    };
};

export default useSubscription;
